.container{
  :global{
    .content{
      flex:1;
      overflow: auto;
      .table{
        th:nth-child(1),td:nth-child(1){
          position: sticky;
          left:3px;
          background-color: #FFF;
        }
        th:nth-child(1){
          background-color: rgb(249, 250, 251);
        }
      }
    }
  }
}

.detail {
  overflow: hidden;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  width: 50vw !important;
  min-width: 800px;
  border: 1px solid #CCC !important;
  height: 0;

  :global {
    .content {
      display: flex;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      flex-direction: column;
    }

    .form {
      display: flex;
      flex: 0;
      flex-direction: column;
    }

    .document {
      aspect-ratio: 856 / 540;
      border: 1px solid #BBB;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
    }

  }
}

.setting {
  overflow: hidden;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  width: 500px !important;
  min-width: 500px;
  border: 1px solid #CCC !important;
  height: 0;

  :global {
    .content {
      display: flex;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      flex-direction: column;
    }

    .form {
      display: flex;
      flex: 0;
      flex-direction: column;
    }

    .document {
      aspect-ratio: 856 / 540;
      border: 1px solid #BBB;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
    }

  }
}