.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-size: cover !important;

  :global {

    .menu {
      background-color: transparent !important;
      border: none !important;
      margin-bottom: 10vh !important;
      -webkit-app-region: drag !important;

      .item {
        -webkit-app-region: none !important;
      }

      .item:hover {
        background-color: transparent !important;

      }
    }

    .content {
      background-color: rgba(255, 255, 255, 0.7) !important;
      width: 400px;
      border-radius: 20px !important;
      padding: 35px !important;
    }

    .footer {
      position: absolute;
      bottom: 10px;
      color: #FFF;
    }

    .captcha{
      display: flex;
      .input{
        flex: 4;
        margin-right: 7px;
      }
      canvas{
        flex: 1;
        height: 100%;
        aspect-ratio: 5 / 2;
      }
    }
  }
}
