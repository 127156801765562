.container {
  display: flex;
  background-color: #ddd;
  flex: 0 30px;
  color: #333;
  font-size: 0.9rem;
  overflow: auto;
  will-change: transform;

  &::-webkit-scrollbar {
    height: 2px !important;
  }

  :global {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
    }

    .label {
      flex: 1;
      border-width: 1px;
      border-style: outset;
      padding: 0 10px;
    }

    .value {
      flex: 2;
      background-color: #ccc;
      border-width: 1px;
      border-style: inset;
      padding: 0 10px;
    }

    .spacer {
      flex: 0 100% !important;
    }
  }
}
