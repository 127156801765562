@import './common';


html, body, #root {
  @include flex;
  width: 100vw;
  height: 100vh;
  padding: 0;
  border: 0;
  margin: 0;
  overflow: hidden;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  line-height: 20px !important;
}


.react-datepicker__close-icon {
  filter: grayscale(1) invert(1) !important;
}

textarea {
  resize: none !important;
}

.ui.dropdown, .ui.input > input, textarea {
  border-color: #BBB !important;
}

.ui.button {
  border-color: #BBB !important;
  outline-color: #BBB !important;
}


.react-datepicker__input-container {
  border-color: #CCC !important;
  outline-color: #CCC !important;
}


.visible.menu.transition{
  min-width: fit-content !important;
  .item{
    .text{
      white-space: nowrap;
    }
  }
}
//
//
//div[data-test-id="react-tree-root"] {
//  display: flex;
//  flex: 1;
//
//  div {
//    flex: 1;
//    border: none;
//    width: 100% !important;
//    font-size: 14px !important;
//  }
//}

.flexHorizontalContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 !important;
  overflow: hidden;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button{
  display: none;
}

.basic.button{
  border: 1px solid #999 !important;
}

