.container {
  flex: 1;
  display: flex;
  overflow: hidden;
  gap: 3px;

  :global {
    .react-datepicker__input-container {
      input {
        border-color: #BBB !important;
      }
    }

    .button {
      box-shadow: none !important;
      border: 1px solid #CCC !important;
      margin: 0 !important;
    }
  }
}

.picker {
  overflow: hidden;
  min-width: 100% !important;
}


.custom{
  display: flex;
  flex-direction: column;
  margin:0 !important;
  padding:0 !important;
  :global{
    .button{
      display: flex !important;
      align-items: center;
      margin:0 !important;
      border: 0 !important;
      height: 99% !important;
    }
  }
}

