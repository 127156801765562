.container {
  display: flex;
  flex: 1;
  padding: 0 !important;
  margin: 0 !important;
  flex-direction: column;
  overflow: hidden;
  user-select: none;

  :global {

    .toolbar {
      display: flex;
      margin-bottom: 7px;

      .toolbar_left, .toolbar_right {
        flex: 1
      }

      .toolbar_right {
        display: flex;
        justify-content: flex-end;
        flex:0;
        min-width: fit-content;
      }
    }

    .content {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: hidden !important;

      .rdg {
        flex: 1;

        .rdg-row-even {
          background-color: #EEE;
        }
      }

      .rdg-editor-container {
        overflow: visible;
      }
    }
  }
}


.column {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #FFF;
  min-width: 300px;
  max-width: 300px;
  border: 1px solid #CCC;
  overflow: hidden !important;

  :global {
    .menu {
      flex: 0 !important;
    }

    .segment {
      flex: 1;
      padding: 0 !important;
      overflow-y: scroll !important;
    }
  }
}


.item {
  outline: 1px solid #DDD;
  cursor: move;
  background-color: #FFF !important;
}

