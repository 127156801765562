.container {
  max-width: 250px !important;
  background-color: #111 !important;
  padding: 0 !important;
  overflow-y: auto !important;
  user-select: none;
  display: flex;
  flex-direction: column;

  :global {
    .title {
      display: flex;
      padding: 14px;
      justify-content: center
    }

    .content{
      flex:1;
    }

    .item {
      display: flex;
      padding: 14px;
      border-top: 1px solid #333;
      cursor: pointer;
    }

    .item:hover {
      background-color: #333;
    }

    .item.child {
      border-top: 0;
    }

    .child_container {
      padding-left: 14px;
      padding-bottom: 7px;
      padding-top: 0;
      opacity: 0.7;
      border-bottom: 1px solid #333;
    }

    .footer{
      display: flex;
      justify-content: center;
      padding: 7px;
    }

  }
}