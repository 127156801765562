.container {
  display: flex;
  flex: 1;
  background-color: #fff;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden !important;
  z-index: 10000;

  :global {
    .segment {
      display: flex;
      flex: 1;
      background-color: #999;
      justify-content: center;
      height: 0;
      overflow: auto !important;

      .sheet {
        display: flex;
        background-color: #fff;
      }

      iframe {
        background-color: #fff;
        flex: 1;
      }
    }
  }
}

.title {
  -webkit-app-region: none !important;
}
