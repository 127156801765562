.container {
  display: flex;
  flex: 1;
  background-color: #fff;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden !important;
  z-index: 10000;

  :global {

    .segments{
      border: 0 !important;
      overflow: hidden;
      border-radius: 0 !important;

      .menu{
        flex:0;
        min-width: 200px;
        max-width: 200px;
        border: 0 !important;
        border-radius: 0 !important;
        .item{
          border-radius: 0 !important;
        }
      }
    }



    .segment:last-child {
      display: flex;
      flex: 1;
      background-color: #3d3e3f !important;
      justify-content: center;
      overflow-y: auto !important;
      border: 0 !important;

      .sheet {
        display: flex;
        background-color: #fff;
      }

      iframe {
        background-color: #fff;
        flex: 1;
        max-width: 794px;
        overflow: hidden;
        border: none;
      }

    }
  }
}

.title {
  -webkit-app-region: none !important;
}
