.container {
  display: flex;
  flex-direction: column;
  line-height: 30px;
  user-select: none;
  cursor: pointer;

  :global {

    .item {

      &.active {
        background-color: #dcf3fc;
      }
    }


    ul {
      margin: 0;
    }

    li {
      list-style: none;
      margin-left: -20px;
    }
  }
}