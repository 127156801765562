.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    //background-image:linear-gradient(#000428, #004e92);
    background-image: url('/Assets/dashboard.jpg');
    background-position: center;
    background-size: cover;
    color: #23b4bd;
    overflow: scroll;
    user-select: none;

    &::-webkit-scrollbar {
        display: none;
    }

    :global {
        .title {
            padding: 0 14px;
            font-size: 1.5rem;
            text-align: center;
            color: #fff;
            margin-bottom: 7px;
            border-radius: 7px;
            display: flex;
            div {
                flex: 1;
            }
            div:nth-child(1) {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 90%;
                opacity: 0.3;
            }
            div:nth-child(2) {
                flex: 1;
                letter-spacing: 7px;
                text-align: center;
                border-left: 40px solid transparent;
                border-right: 40px solid transparent;
                border-top: 40px solid rgba(9, 46, 48, 0.5);
                span {
                    opacity: 0.6;
                    display: block;
                    margin-top: -30px;
                }
            }
            div:nth-child(3) {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                span {
                    opacity: 0.3;
                    font-size: 90%;
                    margin-right: 14px;
                }
            }
        }

        .content {
            display: flex;
            flex: 1;
            background-color: transparent !important;
            border: none !important;
            overflow: scroll;
            height: 0;
        }

        .map {
            display: flex;
            flex: 1;
            flex-direction: column;
            border: 1px solid rgba(35, 180, 189, 0.4);
            background-color: rgba(1, 6, 9, 0.3);
            margin: 7px;
            border-radius: 7px;
        }

        .simulators {
            padding: 7px;
            display: grid;
            flex: 3;
            grid-template-columns: repeat(12, minmax(100px, 1fr));
            align-content: flex-start;
            gap: 14px;
            font-size: 9pt;
            .simulator {
                background-color: rgba(1, 6, 9, 0.7);
                border: 1px solid #23b4bd;
                padding: 7px;
                display: flex;
                border-radius: 7px;
                height: 105px;
                flex-direction: column;
                filter: brightness(0.5);
                overflow: hidden;
                text-align: center;
                text-overflow: ellipsis;

                div {
                    white-space: nowrap;
                }

                .image {
                    text-align: center;
                    font-size: 2.6rem;
                    margin-top: 3px;
                    margin-bottom: 7px;
                }
                .name {
                    display: flex;
                    justify-content: center;
                }
            }

            .online {
                cursor: pointer;
                background-color: #0e484b;
                filter: brightness(1);
                &:active {
                    filter: brightness(0.8);
                }
                &:hover {
                    filter: brightness(1.2);
                }
            }
        }

        .footer{
          background-color: #001a27 !important;
          .label{
            background-color: #001a27 !important;
          }
          .label:hover{
            color: #FFF !important;
          }
          .visible.menu{
            background-color: #001a27 !important;
            span{
              color: #FFF !important;
            }
          }
        }

    }
}

.modal {
    display: flex;
    flex-direction: column;
    color: #23b4bd;
    :global {
        .title {
            display: flex;
            margin: 10px 0;
            font-size: 1.5rem;
            div {
                flex: 1;
            }
            div:last-child {
                text-align: right;
            }
        }
        video {
            border: 1px solid #000;
            background-color: #333;
            min-width: 100%;
            aspect-ratio: 21 / 9;
        }
    }
}

.map {
    display: flex;
    flex: 1;
}
