.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  height: 0 !important;
  margin: 0 !important;
  padding:0 !important;
  border:0 !important;
  border-radius: 0 !important;


  :global {
    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      h2{
        margin: 0 !important;
        margin-left: 7px !important;
      }
    }
  }


}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 !important;
  border-color: #e0e0e0 !important;
  outline: none !important;
  overflow: hidden;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.error{
  //animation: blinker 5s linear infinite;

}