.container {
    height: 0;

    :global {
        .segment{
            display: flex;
            flex:1;
            background-color: #F00;
            flex-direction: column;
        }

        .content{
            flex:1;
            overflow-y: auto;
            overflow-x: hidden;
        }

        thead tr {
            position: sticky;
            top: 1px;
            z-index: 10000;
        }

        .input {
            border: 0 !important;
            background-color: transparent !important;
            input {
                border: 0 !important;
                background-color: transparent !important;
            }
        }

        .dropdown{
            padding: 0 !important;
            border: 0 !important;
            background-color: transparent !important;
            display: flex !important;
            flex-direction: row;
            box-shadow: none;
            margin:7px !important;
            a {
                display: flex !important;
                justify-content: center;
                align-items: center;
                background-color: #EEE !important;
                box-shadow: none;
                &:active{
                    border:0 !important;
                    outline: 0 !important;
                }
            }

        }
        .dropdown.visible{
            box-shadow: none;
            border: 0 !important;
        }
    }
}
